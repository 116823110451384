export default [
    {
        type: 'pageview.response',
        handler: (e) => {
            if (!window.OneSignal) {
                return;
            }

            const { pageViewResponse } = e.detail;
            const applicant = pageViewResponse.applicant;

            const tags = {
                browser_uuid: pageViewResponse.buid,
                mobile: pageViewResponse.mobile,
                last_promo_id: pageViewResponse.cpanelClick.promoId,
            };

            try {
                if (applicant.homeAddress && applicant.homeAddress.state) {
                    tags['address_state'] = applicant.homeAddress.state;
                }

                // if (applicant.email) {
                //     tags['email'] = applicant.email;
                //     window.OneSignal.setEmail(applicant.email);
                // }

                if (applicant.nameFirst) {
                    tags['name_first'] = applicant.nameFirst;
                }

                if (applicant.loanReason && applicant.loanReason.label) {
                    tags['loan_reason'] = applicant.loanReason.label;
                }

                if (applicant.creditRating && applicant.creditRating.label) {
                    tags['self_reported_credit'] = applicant.creditRating.label;
                }

                if (applicant.totalDebt && applicant.totalDebt.label) {
                    tags['total_debt'] = applicant.totalDebt.label;
                }

                if (applicant.vehiclePaidOff && applicant.vehiclePaidOff.label) {
                    tags['vehicel_paid_off'] = applicant.vehiclePaidOff.label;
                }

                if (applicant.businessOwner) {
                    tags['business_owner'] = applicant.businessOwner ? 'true' : 'false';
                }

                if (applicant.military) {
                    tags['military'] = applicant.military ? 'true' : 'false';
                }
            } catch (ex) {
                console.error(ex.message);
            }

            window.OneSignal.push(() => {
                window.OneSignal.sendTags(tags).then((tagsSent) => {
                    console.log(tagsSent);
                });
            });

            window.OneSignal.push(() => {
                window.OneSignal.setExternalUserId(pageViewResponse.buid);
            })
        }
    },
];
