module.exports = [{
      plugin: require('../plugins/gatsby-plugin-redux/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WG4Z2NG","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.finance-matrix.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FinanceMatric.com","short_name":"Finance Matrix","lang":"en-US","dir":"ltr","gcm_sender_id":"482941778795","background_color":"#ffffff","theme_color":"#00998A","display":"standalone","orientation":"portrait-primary","description":"Looking for quick cash?","scope":"/","start_url":"/","icon":"./static/assets/images/logos/favicon-source.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"081c2c3c107b79a10bd2fa9fa67156bc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
